.offCanvas-box {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.offCanvas-box.active {
  transform: translateX(0);
}

.offCanvas-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.offCanvas-overlay.active {
  display: block;
}

.mobile-menu .menu-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          cursor: pointer;
        }

        .mobile-menu .down-btn {
          transition: transform 0.3s ease;
        }

        .mobile-menu .down-btn.active {
          transform: rotate(180deg);
        }

        .mobile-menu .sub-menu {
          padding-left: 20px;
        }

        .mobile-menu .sub-menu li {
          padding: 8px 0;
        }

        .menu-item-children {
          position: relative;
        }

        .menu-item-children .sub-menu {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          background: white;
          min-width: 200px;
          box-shadow: 0 2px 5px rgba(0,0,0,0.1);
          z-index: 1;
        }

        .menu-item-children:hover .sub-menu {
          display: block;
        }

        @media (max-width: 991px) {
          .menu-item-children .sub-menu {
            position: static;
            box-shadow: none;
            display: none;
          }

          .menu-item-children .sub-menu.show {
            display: block;
          }
        }

        .mobile-menu-content {
          padding: 1rem;
        }
        
        .mobile-menu-content .navigation {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        
        .mobile-menu-content .menu-item {
          padding: 0.75rem 0;
          border-bottom: 1px solid rgba(0,0,0,0.1);
        }
        
        .mobile-menu-content .menu-item:last-child {
          border-bottom: none;
        }
        
        .mobile-menu-content .menu-item a {
          color: #333;
          text-decoration: none;
          display: block;
          font-weight: 500;
        }
        
        .mobile-menu-content .menu-title {
          padding: 0.5rem 0;
          cursor: pointer;
          color: #333;
          font-weight: 500;
        }
        
        .mobile-menu-content .has-dropdown .menu-title {
          margin-bottom: 0.5rem;
        }
        
        .mobile-menu-content .dropdown-menu {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease-out;
          padding-left: 1rem;
          margin-top: 0.5rem;
        }
        
        .mobile-menu-content .dropdown-menu.show {
          max-height: 500px;
          transition: max-height 0.3s ease-in;
        }
        
        .mobile-menu-content .dropdown-menu li {
          padding: 0.5rem 0;
        }
        
        .mobile-menu-content .dropdown-menu a {
          color: #666;
          font-weight: normal;
        }
        
        /* Active States */
        .mobile-menu-content .menu-item.active > a,
        .mobile-menu-content .dropdown-menu li.active > a {
          color: #FF921C;
          font-weight: 600;
        }
        
        /* Transition for dropdown arrow */
        .transition-transform {
          transition: transform 0.3s ease;
        }
        
        .rotate-180 {
          transform: rotate(180deg);
        }
        
        /* Media Queries */
        @media (min-width: 992px) {
          .mobile-menu-content {
            display: none;
          }
        }